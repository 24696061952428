import Logger from 'src/lib/Utils/Logger';

export const getJson = async (url: string) => {
  const path = `/_/media/${url.split('/media/')[1]}`;
  return fetch(path, {
    method: 'GET',
    headers: new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
  })
    .then(res => {
      // need to use text conversion for rebates, I'm guessing the format was messed up at some point?
      // seems like it might be safer to convert all to text and then parse in case the format changes on other JSON files
      return res.text();
    })
    .then(text => {
      return JSON.parse(text);
    })
    .catch(err => {
      Logger(err, { componentName: 'Find It Duke getJson' });
      return null;
    });
};
