import { PEContentType, PEDataItemType } from '../../api/jsonTypes';
import { ResultBarProps } from '../types';

// non HVAC estimates
const ResultBar = ({ header, amount }: ResultBarProps) => {
  return (
    <div className="p-16 sm:p-24 w-full max-w-md rounded-md border border-gray">
      <p className="flex flex-row justify-between">
        <span>{header}</span>
        <span>{amount}</span>
      </p>
    </div>
  );
};

const RegularResult = ({
  Average,
  Min,
  Max,
  tableHeaders,
}: PEDataItemType & { tableHeaders: PEContentType['Table Headers'] }) => {
  return (
    <div className="flex flex-col gap-16 mt-24">
      <ResultBar header={tableHeaders.Average} amount={`$${Average}`} />
      <ResultBar header={tableHeaders.Min} amount={`$${Min}`} />
      <ResultBar header={tableHeaders.Max} amount={`$${Max}`} />
    </div>
  );
};

export default RegularResult;
