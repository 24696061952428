import Link from 'src/components/Common/Link';
import { BottomButtonsProps } from '../types';

const BottomButtons = ({
  startOver,
  findContractorLink,
  findContractorButtonText = 'Find a Contractor',
  startOverButtonText = 'Estimate Another Project',
  headerText,
  sendFindContractorClickAnalytics,
}: BottomButtonsProps) => {
  return (
    <>
      <p className="sm:text-center mb-16 sm:mb-24">{headerText}</p>
      <div className="flex flex-1 flex-col sm:flex-row-reverse justify-center gap-24">
        <Link
          value={{ ...findContractorLink, text: findContractorButtonText }}
          className="btn btn-primary"
          onClick={() => sendFindContractorClickAnalytics?.()}
        />
        <button className="btn btn-secondary" onClick={startOver}>
          {startOverButtonText}
        </button>
      </div>
    </>
  );
};

export default BottomButtons;
