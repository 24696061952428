import { PEDataItemType } from '../../api/jsonTypes';

export const roundAmount = (amt: string) => {
  const n = Number(amt.replace(/,/g, ''));
  if (!isNaN(n)) {
    return Math.round(n).toLocaleString();
  }
  return amt;
};

export const roundResultNumbers = (data: PEDataItemType) => {
  const dataCopy = { ...data };
  dataCopy.Average = roundAmount(data.Average);
  dataCopy.Max = roundAmount(data.Max);
  dataCopy.Min = roundAmount(data.Min);
  return dataCopy;
};
