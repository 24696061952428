import { TableResultProps } from '../types';
import './tableStyles.css';

// HVAC estimates use Seer numbers, Solar uses KW numbers in numArr
const TableResult = ({ data, tableHeaders, projectHeaderKey }: TableResultProps) => {
  const numArr = Object.keys(data);
  return (
    <table id="projectEstimatorTable" className="text-left w-full">
      <thead>
        <tr>
          <td></td>
          {numArr.map((num: string) => (
            <th key={num} scope="col">
              {/* eslint-disable-next-line no-template-curly-in-string */}
              {tableHeaders[projectHeaderKey]?.replace('${number}', num)}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">{tableHeaders.Average}</th>
          {numArr.map((num: string) => (
            <td key={`avg-${num}`}>${data[num].Average}</td>
          ))}
        </tr>
        <tr>
          <th scope="row">{tableHeaders.Min}</th>
          {numArr.map((num: string) => (
            <td key={`low-${num}`}>${data[num].Min}</td>
          ))}
        </tr>
        <tr>
          <th scope="row">{tableHeaders.Max}</th>
          {numArr.map((num: string) => (
            <td key={`high-${num}`}>${data[num].Max}</td>
          ))}
        </tr>
      </tbody>
    </table>
  );
};

export default TableResult;
