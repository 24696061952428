const SkeletonLoader = () => {
  return (
    <div className="flex flex-col md:flex-row md:flex-wrap container-xs md:container-2xl p-24 gap-24 md:gap-32 justify-center animate-pulse">
      <div className="w-full md:w-288 h-56 bg-white rounded-md" />
      <div className="w-full md:w-288 h-56 bg-white rounded-md" />
      <div className="w-full md:w-144 h-56 bg-gray rounded-md" />
    </div>
  );
};

export default SkeletonLoader;
