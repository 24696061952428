import { MobileTableSectionProps, TableResultProps } from '../types';
import './tableStyles.css';

const TableSection = ({ header, labels, amounts }: MobileTableSectionProps) => {
  return (
    <div className="border-t py-16 px-16 border-gray text-left max-w-2xs">
      <table className="border-collapse">
        <thead>
          <tr>
            <th colSpan={2} scope="col" className="text-lg pb-6">
              {header}
            </th>
          </tr>
        </thead>
        <tbody>
          {labels.map((label, i) => {
            return (
              <tr key={`${label}-${i}`} className="h-32">
                <th scope="row" className="pr-20">
                  {label}
                </th>
                <td>{amounts[i]}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const TableResultMobile = ({ data, tableHeaders, projectHeaderKey }: TableResultProps) => {
  const numsArr = Object.keys(data);
  const labels = numsArr.map((num: string) =>
    // eslint-disable-next-line no-template-curly-in-string
    tableHeaders[projectHeaderKey]?.replace('${number}', num)
  );

  return (
    <div className="mt-24">
      <TableSection
        header={tableHeaders.Average}
        labels={labels}
        amounts={numsArr.map((s: string) => `$${data[s].Average}`)}
      />
      <TableSection
        header={tableHeaders.Min}
        labels={labels}
        amounts={numsArr.map((s: string) => `$${data[s].Min}`)}
      />
      <TableSection
        header={tableHeaders.Max}
        labels={labels}
        amounts={numsArr.map((s: string) => `$${data[s].Max}`)}
      />
    </div>
  );
};

export default TableResultMobile;
