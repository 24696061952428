import LazyImage from 'src/components/Common/LazyImage';
import SvgLoader from 'src/components/Common/SvgLoader';
import { RebateCardType } from '../../api/jsonTypes';
import { RebateCardsProps, SendRebateClickAnalyticsType } from '../types';

// this is basically a rectangular card (ie nav card) with xs styles used for xs,lg,xl and desktop styles used for sm,md
// https://electron.duke-energy.com/components/public-site/rectangular-card/
const RectangularCard = ({
  img,
  info,
  link,
  title,
  sendRebateClickAnalytics,
}: RebateCardType & SendRebateClickAnalyticsType) => {
  const titleWithoutArrow = title?.replace(/>\s*$/, '').trim();

  return (
    <li className="p-12 sm:w-1/3 lg:p-12 lg:w-full">
      <div className="relative flex sm:flex-col sm:h-full lg:flex-row lg:h-auto">
        <a
          className="block h-fit mr-12 sm:mr-0 lg:mr-12 sm:mb-12 lg:mb-0 flex-shrink-0"
          href={link}
          tabIndex={-1}
          aria-hidden="true"
        >
          <LazyImage
            className="object-cover aspect-1/1 sm:aspect-16/10 lg:aspect-1/1 w-80 sm:w-full lg:w-80 h-80 sm:h-auto lg:h-80 rounded-md bg-gray-light"
            src={img}
            alt=""
          />
        </a>
        <div className="flex flex-col flex-grow justify-center sm:justify-start lg:justify-center text-left">
          <h3 className="text-xl">
            <a
              className="block text-teal-darker no-underline hover:underline"
              href={link}
              onClick={() => sendRebateClickAnalytics(link, titleWithoutArrow)}
            >
              {titleWithoutArrow}
              <span className="whitespace-nowrap">
                &zwnj;
                <SvgLoader name="Arrow" className="inline-flex link-chevron" />
              </span>
            </a>
          </h3>
          <p className="mt-4 md:mt-6 lg:mt-4 line-clamp-6">{info}</p>
        </div>
      </div>
    </li>
  );
};

const RebateCards = ({ rebateData, sendRebateClickAnalytics }: RebateCardsProps) => {
  if (rebateData?.cards?.length) {
    const { cards, title } = rebateData;
    return (
      <div className="min-w-288 sm:mx-36 lg:mx-0">
        {title && <h2 className="text-xl text-gray-darker mb-12 ml-12">{title}</h2>}
        <ul className="lg:m-0 sm:mx-auto sm:flex sm:flex-wrap sm:justify-center lg:block sm:container-xl lg:container-none">
          {cards.map(card => (
            <RectangularCard key={card.title} {...{ ...card, sendRebateClickAnalytics }} />
          ))}
        </ul>
      </div>
    );
  }
  return null;
};

export default RebateCards;
