import { RichText } from 'src/components/Common/@electron/RichText';
import useMediaQuery from 'src/lib/Hooks/useMediaQuery';
import { PEDataItemType } from '../../api/jsonTypes';
import { ResultsProps, TableDataType } from '../types';
import { roundResultNumbers } from '../util/formatters';
import RegularResult from './RegularResult';
import TableResult from './TableResult';
import TableResultMobile from './TableResultMobile';

// most of the HVAC results are tables with seer numbers
const Results = ({
  data,
  title,
  description,
  seerExplanation,
  tableHeaders,
  tableProjectType,
}: ResultsProps) => {
  const showDesktopTable = useMediaQuery('sm');
  const isTableResult = data.length > 1 && !!tableProjectType;
  const projectHeaderKey = tableProjectType === 'solar' ? 'KW' : 'Seer';

  // rounding numbers here
  const roundedData = data.map(item => roundResultNumbers(item));
  const roundedRegResult = roundedData[0];

  // creating obj for table results for easier reference by seer/kw number, ie {15: {...}, 16: {...}, 17: {...}}
  const tableDataObj: TableDataType = {};

  if (isTableResult) {
    roundedData.forEach((item: PEDataItemType) => {
      const seerDigits = item.Seer?.replace(/[^0-9]/g, '');
      const kwDigits = item.KW?.replace(/[^0-9]/g, '');
      if (seerDigits && parseInt(seerDigits) > 0) {
        tableDataObj[seerDigits] = item;
      } else if (kwDigits && parseInt(kwDigits) > 0) {
        tableDataObj[kwDigits] = item;
      }
    });
  }

  return (
    <div className="bg-white p-48 rounded-md">
      {title && <p className="text-xl mb-6 text-gray-darker">{title}</p>}
      <RichText tag="p" value={description} className="mb-8" />
      {!isTableResult && <RegularResult {...roundedRegResult} tableHeaders={tableHeaders} />}
      {isTableResult && Object.keys(tableDataObj).length > 0 && (
        <>
          {showDesktopTable && (
            <TableResult data={tableDataObj} {...{ tableHeaders, projectHeaderKey }} />
          )}
          {!showDesktopTable && (
            <TableResultMobile data={tableDataObj} {...{ tableHeaders, projectHeaderKey }} />
          )}
          {seerExplanation && tableProjectType === 'hvac' && (
            <p className="mt-8 text-sm">{seerExplanation}</p>
          )}
        </>
      )}
    </div>
  );
};

export default Results;
